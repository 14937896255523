import { DownloadPassIcon, InawoPassIcon } from "@src/components/Icon/Icons";
import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import { toast } from "react-hot-toast";
import { toPng } from "html-to-image";

const Index = ({
  passImage,
  eventSlug,
  setOpen,
}: {
  passImage: string;
  eventSlug: string;
  setOpen: (value: boolean) => void;
}) => {
  const cardRef = React.useRef<HTMLDivElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [imgData, setImgData] = React.useState<string | null>(null);

  //Preload the background image
  React.useEffect(() => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      setImageLoaded(true);
    };
    img.onerror = () => {
      console.log("Failed to load background image");
    };
    img.src = passImage;
  }, [passImage]);

  const handleDownload = async () => {
    if (cardRef.current) {
      try {
        const dataUrl = await toPng(cardRef.current);
        const link = document.createElement("a");
        link.download = "love-card.png";
        link.href = dataUrl;
        link.click();
        toast.success("Pass Image downloaded successfully");
      } catch (error) {
        console.error("Error generating image:", error);
        toast.error("Failed to download card");
      }
    }
  };

  return (
    <div className="flex flex-col gap-[40px] items-center w-full lg:w-[510px] mx-auto bg-white py-10 rounded-[30px]">
      <div className="flex flex-col gap-[12px] items-center">
        <h3 className="font-medium text-[26px]">Your pass</h3>
        <p className="text-[#71717A] text-[14px]">Show this at the venue</p>
      </div>
      <div className="flex flex-col gap-[16px] items-center">
        {/* card to be downloaded starts here */}
        <div
          ref={cardRef}
          style={{ border: "0.7px solid #D9D9D9" }}
          className="w-[220px] shadow-lg h-[310px] rounded-[22px] py-[9px] px-[15px] flex flex-col items-center justify-between relative"
        >
          <img
            src={passImage}
            ref={imgRef}
            alt="pass image"
            className="absolute inset-0 w-full h-full object-cover rounded-[22px]"
          />
          <p className="my-[11px] text-[11px] font-medium text-white text-red absolute z-10">
            #{eventSlug}
          </p>
          <div
            className="w-[90%] rounded-[22px] bg-[#FFFFFFCC] h-[211px] px-[10px] py-[10px] flex flex-col items-center justify-between absolute bottom-[12px]"
            style={{ backdropFilter: "blur(3px)" }}
          >
            <div>
              <p className="text-[9px] text-[#4D4D4DE8] mb-[2px]">Guest code</p>
              <p className="text-[10px] text-[#4D4D4D4D]">{"TWE2110"}</p>
            </div>
            <div className="w-[105px] h-[105px] border border-[#3D516080] rounded-[20px] bg-gradient-to-b from-[#E6FA5200] to-[#E6FA5233] p-[20px]">
              <QRCodeCanvas value="TWE2110" fgColor="#4F7770" size={65} />
            </div>
            <div className="flex flex-col items-center justify-center">
              <p className="mb-[2px] text-[#4D4D4D99] text-[10px] text-center">
                This is your pass to the wedding
              </p>
              <InawoPassIcon />
            </div>
          </div>
        </div>
        {/* card to be downloaded ends here */}
        <button
          onClick={handleDownload}
          className="flex items-center justify-center gap-[8px] bg-[#FFFBEB] w-[188px] mx-auto h-[35px] rounded-[7px] py-[7px] w-[188px] mx-auto px-[12px] font-medium shadow-lg"
          style={{ border: "0.579px solid #5C5C5C" }}
          disabled={!imageLoaded}
        >
          <DownloadPassIcon />
          <p className="text-[#141414] text-[12px] font-normal">
            Download your pass
          </p>
        </button>
      </div>
    </div>
  );
};

export default Index;
