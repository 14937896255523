import * as React from "react";
import Icon from "../Icon";
import styles from "./styles.module.scss";
import Boop from "src/components/Boop";
import Dialog from "src/components/Dialog";
import { MemoMessageModal as MessageModal } from "src/components/modals/MessageModal";
// import { MemoMoneybagModal as MoneybagModal } from "src/components/modals/MoneyBagModal";
import { MemoCashGiftModal as CashGiftModal } from "src/components/modals/CashGiftModal";
import Invitation from "src/components/modals/Invitation";
// import StoreModal from "src/components/modals/StoreModal";
import { MemoWishlist as WishlistModal } from "src/components/modals/WishlistModal";
import { MemoLivehallModal as LivehallModal } from "src/components/modals/LivehallModal";
import { IconType } from "@src/utils/icon";
import { motion } from "framer-motion";
import {
  eventBySlug,
  Currency,
  getEventStreamers,
  getForeignAccountDetails,
  getLiveSchedule,
} from "@src/@types/api.d";
import { getCookie } from "cookies-next";
import { useQuery } from "@apollo/client";
import { GetForeignWalletDetails } from "@src/graphql/queries";
import toast from "react-hot-toast";
import LiveSchedules from "../LiveSchedules";
import { getWishListItems, getWishListItemsVariables } from "@src/@types/api.d";
import { GetWishListItems } from "@src/graphql/queries";
import YourPass from "../modals/YourPass";

type Props = {
  eventId: number;
  hasWallet?: boolean;
  slugData: eventBySlug | undefined;
  loading?: boolean;
  getEventStreamersData: getEventStreamers;
  liveSchedules: getLiveSchedule;
  access_control: boolean;
};

type deckType = {
  icon: IconType;
  title: string;
  modal: JSX.Element;
  open: boolean;
  fillScreen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Floater: React.FC<Props> = ({
  getEventStreamersData,
  eventId,
  slugData,
  liveSchedules,
  hasWallet,
  access_control,
}) => {
  const [openInvitation, setOpenInvitation] = React.useState<boolean>(false);
  const [openWishlist, setOpenWishlist] = React.useState<boolean>(false);
  const [openCashGift, setOpenCashGift] = React.useState<boolean>(false);
  const [openMessage, setOpenMessage] = React.useState<boolean>(false);
  const [openPass, setOpenPass] = React.useState<boolean>(false);
  // const [openMoneyBag, setMoneyBag] = React.useState<boolean>(false);
  const [openStream, setOpenStream] = React.useState<boolean>(false);
  // const [openStore, setOpenStore] = React.useState<boolean>(false);
  const [accessToken, setAccessToken] = React.useState<string>("");

  React.useEffect(() => {
    setAccessToken(getCookie("tsl") as string);
  }, []);

  const { data: foreignWalletDetails, loading: _foreignDetailsLoading } =
    useQuery<getForeignAccountDetails>(GetForeignWalletDetails, {
      skip: !eventId,
      variables: {
        eventId: !!eventId ? eventId : 0,
      },
      fetchPolicy: "network-only",
      onError(error) {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      },
    });

  const foreignDetails = React.useMemo(() => {
    const res = foreignWalletDetails?.getForeignAccountDetails.find(
      (item) => item?.currency === Currency.gpb || Currency.usd
    );
    return res;
  }, [foreignWalletDetails]);

  const { data: getWishListItemsData, loading: getWishListItemsLoading } =
    useQuery<getWishListItems, getWishListItemsVariables>(GetWishListItems, {
      skip: !eventId,
      variables: {
        eventId,
        pageSize: 50,
      },
    });

  const deck: deckType[] = [
    {
      icon: "pass",
      title: "Your Pass",
      modal: (
        <YourPass
          setOpen={setOpenPass}
          passImage={slugData?.eventBySlug?.pass_image ?? ""}
          eventSlug={slugData?.eventBySlug?.slug ?? ""}
        />
      ),
      open: openPass,
      setOpen: setOpenPass,
      fillScreen: false,
    },
    {
      icon: "invitation",
      title: "Invitation",
      modal: (
        <Invitation
          name={slugData?.eventBySlug?.name ?? ""}
          date={slugData?.eventBySlug?.date ?? ""}
          eventId={eventId}
          setOpen={setOpenInvitation}
          access_control={
            slugData?.eventBySlug?.event_preference?.access_control ?? false
          }
        />
      ),
      open: openInvitation,
      setOpen: setOpenInvitation,
      fillScreen: false,
    },
    {
      icon: "wishlist",
      title: "Wishlist",
      modal: <WishlistModal eventId={eventId} setOpen={setOpenWishlist} />,
      open: openWishlist,
      setOpen: setOpenWishlist,
      fillScreen: false,
    },
    {
      icon: "cashGift",
      title: "Cash gift",
      modal: (
        <CashGiftModal
          foreignDetails={foreignDetails}
          eventId={eventId}
          setOpen={setOpenCashGift}
        />
      ),
      open: openCashGift,
      setOpen: setOpenCashGift,
      fillScreen: false,
    },
    {
      icon: "message",
      title: "Message",
      modal: (
        <MessageModal
          name={slugData?.eventBySlug?.name ?? ""}
          eventId={eventId}
          setOpen={setOpenMessage}
        />
      ),
      open: openMessage,
      setOpen: setOpenMessage,
      fillScreen: false,
    },
    {
      icon: "stream",
      title: "Live Stream",
      modal:
        // TODO: we'll later change *true* to a logic that checks live hall kind
        true ? (
          <LiveSchedules schedules={liveSchedules} />
        ) : (
          <LivehallModal
            src={
              !!getEventStreamersData.getEventStreamers &&
              getEventStreamersData.getEventStreamers!.length > 0
                ? getEventStreamersData.getEventStreamers![0]?.playback_url
                : ""
            }
            isLive={
              !!getEventStreamersData.getEventStreamers &&
              getEventStreamersData.getEventStreamers!.length > 0
                ? !!getEventStreamersData.getEventStreamers![0]?.is_streaming
                : false
            }
            eventId={eventId}
            setOpen={setOpenStream}
          />
        ),
      open: openStream,
      setOpen: setOpenStream,
      fillScreen: true,
    },
    // {
    // 	icon: "store",
    // 	title: "Store",
    // 	modal: <StoreModal eventId={eventId} setOpen={setOpenStore} />,
    // 	open: openStore,
    // 	setOpen: setOpenStore,
    // },
  ];

  const wishlistItems = getWishListItemsData?.getWishListItems ?? [];

  const filteredDeck = React.useMemo(() => {
    return deck?.filter((item) => {
      if (item.title === "Wishlist" && wishlistItems?.length < 1) {
        return false;
      } else if (
        item.title === "Your Pass" &&
        slugData?.eventBySlug?.event_preference?.access_control === false
      ) {
        return false;
      }
      return true;
    });
  }, [deck, wishlistItems]);
  return (
    <motion.div className={styles["floater"]}>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        whileInView={{
          opacity: 1,
          scale: 1,
          transition: {
            delay: 1.4,
            duration: 0.5,
            ease: [0.79, 0.14, -0.15, 0.86],
          },
        }}
        className={styles["floater-container"]}
      >
        {filteredDeck
          // remove atuh features if the user isn't authenticated
          .filter((item) =>
            !!accessToken
              ? item
              : !["Invitation", "Store", "Message"].includes(item.title)
          )
          .filter((item) =>
            liveSchedules?.getLiveSchedule?.recordCount ||
            !!getEventStreamersData.getEventStreamers?.[0]?.event
              ?.event_preference?.live_hall
              ? item
              : !["Live Stream"].includes(item.title)
          )
          .filter((item) =>
            !liveSchedules?.getLiveSchedule?.recordCount ||
            (!!getEventStreamersData.getEventStreamers?.[0]?.event
              ?.event_preference?.private &&
              !accessToken)
              ? !["Live Stream"].includes(item.title)
              : item
          )
          .filter((item) =>
            liveSchedules?.getLiveSchedule?.recordCount
              ? item
              : !["Live Stream"].includes(item.title)
          )
          .map((item) => (
            <Dialog
              key={item.title}
              fillScreen={item.fillScreen}
              trigger={
                <Boop y={2}>
                  <div className={styles["floater-icon"]}>
                    <div className={styles["floater-icon-box"]}>
                      <Icon iconName={item.icon} />
                    </div>
                    <p className={styles["floater-paragraph"]}>{item.title}</p>
                  </div>
                </Boop>
              }
              open={item.open}
              onOpenChange={item.setOpen}
            >
              {item.modal}
            </Dialog>
          ))}
      </motion.div>
    </motion.div>
  );
};

export default Floater;
