import ClientOnly from "@src/components/ClientOnly";
import Floater from "@src/components/Template2/Floater";
import { IHome } from "@src/components/Home";
import React from "react";
import Hero from "../Hero";
import Parts from "../Parts";
import Backstory from "../Backstory";
import Schedule from "../Schedule";
import Rsvp from "../Rsvp";
import CashGift from "../CashGift";
import AddPhotos from "../AddPhotos";
import Footer from "../Footer";
import AsoEbi from "../AsoEbiLink";
import Masonry from "@src/components/Masonry";

const Home: React.FC<IHome> = ({
  slug,
  eventBySlugData,
  eventId,
  eventPartsData,
  backStoryData,
  eventVendorData,
  albumDetailsData,
  heroImageData,
  hasWallet,
  getEventStreamersData,
  liveSchedules,
}) => {
  return (
    <>
      <ClientOnly>
        <Floater
          eventId={eventId}
          slugData={eventBySlugData}
          hasWallet={hasWallet}
          getEventStreamersData={getEventStreamersData}
          liveSchedules={liveSchedules}
          access_control={
            eventBySlugData?.eventBySlug?.event_preference?.access_control ??
            false
          }
        />
        <Hero
          heroImageData={heroImageData}
          eventId={eventId}
          slugData={eventBySlugData}
        />
        <Parts
          liveSchedules={liveSchedules}
          eventId={eventId}
          eventPartsData={eventPartsData}
        />
        <Backstory backStoryData={backStoryData} eventId={eventId} />
        <Schedule
          liveSchedules={liveSchedules}
          eventId={eventId}
          eventPartsData={eventPartsData}
        />
        <AsoEbi />
        <Masonry
          eventSlug={slug}
          eventId={eventId}
          albumDetailsData={albumDetailsData}
        />
        <Rsvp
          slugData={eventBySlugData}
          eventId={eventId}
          access_control={
            eventBySlugData?.eventBySlug?.event_preference?.access_control ??
            false
          }
        />
        <CashGift eventId={eventId} hasWallet={hasWallet} />
        <AddPhotos />
        <Footer slug={eventBySlugData} />
      </ClientOnly>
    </>
  );
};

export default Home;
