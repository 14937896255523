import React from "react";
import Dialog from "@src/components/Dialog";
import { getCookie } from "cookies-next";
import Button from "@src/components/Button";
import Invitation from "@src/components/modals/Invitation";
import { eventBySlug } from "@src/@types/api";

interface IRsvp {
  eventId: number;
  slugData: eventBySlug | undefined;
  access_control: boolean;
}

const Rsvp: React.FC<IRsvp> = ({ eventId, slugData }) => {
  const [openRsvp, setOpenRsvp] = React.useState<boolean>(false);
  const currentAccessToken = getCookie("tsl") as string;
  const [show, setShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShow(true);
  }, []);

  //   show &&
  //   currentAccessToken
  return true ? (
    <div className="w-full h-max  text-inawo-black-900/80">
      <div className="container py-[3rem] md:py-[7.5rem]  flex flex-col items-center w-full min-h-[24.rem]">
        <h3 className="font-cinzel text-[1.5rem] md:text-[2.25rem] font-[700] uppercase mb-4">
          Will you be joining us?
        </h3>
        <span className="font-temp text-[1rem] md:text-[1.25rem] font-[300] mb-8">
          Kindly reply by Feb 29
        </span>

        <Dialog
          trigger={
            <Button label="Reply Now" design="template-2" type="submit">
              <span className="!text-white">Reply now</span>
            </Button>
          }
          open={openRsvp}
          onOpenChange={setOpenRsvp}
        >
          <Invitation
            name={slugData?.eventBySlug?.name ?? ""}
            date={slugData?.eventBySlug?.date ?? ""}
            eventId={eventId}
            setOpen={setOpenRsvp}
            access_control={
              slugData?.eventBySlug?.event_preference?.access_control ?? false
            }
          />
        </Dialog>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Rsvp;
