import * as React from "react";
import Icon from "../Icon";
import styles from "./parts.module.scss";
import { motion } from "framer-motion";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { eventParts, getLiveSchedule } from "@src/@types/api.d";
import { getCookie } from "cookies-next";
import DialogModal from "../Dialog";
import LiveSchedules from "../LiveSchedules";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

export type IParts = {
  eventId: number;
  eventPartsData: eventParts;
  liveSchedules?: getLiveSchedule;
};

const Parts: React.FC<IParts> = ({
  eventId,
  eventPartsData,
  liveSchedules,
}) => {
  const currentAccessToken = getCookie("tsl") as string;
  // const currentAccessToken = "hfjjksuirjskwioeiweujrurj847748jdn" as string;

  const [show, setShow] = React.useState(false);
  const [openMap, setOpenMap] = React.useState<boolean>(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "",
    libraries: ["places"],
  });

  const onLoadMarker = (marker: any) => {
    // console.log("Marker", marker?.position?.lat);
  };

  const handleViewDirections = ({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }) => {
    const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving`;
    window.open(directionsUrl, "_blank");
  };

  const handleFindHotelsNearby = ({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }) => {
    const hotelsUrl = `https://www.google.com/maps/search/hotels/@${latitude},${longitude},15z`;
    window.open(hotelsUrl, "_blank");
  };

  React.useEffect(() => {
    setShow(true);
  }, []);

  const addOneDay = (dateTime: string) => {
    var date = new Date(dateTime);
    date.setHours(date.getHours() + 24);
    var isoDate = date.toISOString();
    return isoDate;
  };

  return !!eventPartsData?.eventParts &&
    eventPartsData?.eventParts?.length > 0 ? (
    <div className={styles["home__event-types-container"]}>
      {!!eventPartsData.eventParts && eventPartsData.eventParts.length === 0 ? (
        <></>
      ) : (
        <motion.div
          initial={{ opacity: 1 }}
          whileInView={{
            opacity: 1,
            transition: {
              delayChildren: 0.5,
              staggerChildren: 0.5,
            },
          }}
          className={styles["home__event-types"]}
        >
          {eventPartsData?.eventParts?.map((item, idx) => {
            return (
              <motion.div
                initial={{ opacity: 0, y: -15 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.2,
                    duration: 1,
                    ease: [0.79, 0.14, 0.15, 0.86],
                  },
                }}
                key={`home action ${idx}`}
                className={styles["home__event-types-box"]}
              >
                <div className={styles["home__event-types-icon"]}>
                  <Icon iconName="roses" />
                </div>
                <h1 className={styles["home__event-types-heading"]}>
                  {item.name}
                </h1>
                <p className={styles["home__event-types-date"]}>
                  {new Date(item.time).toDateString()}
                </p>
                <p className={styles["home__event-types-time"]}>
                  {new Date(item.time).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
                {show && currentAccessToken ? (
                  <p className={styles["home__event-types-time"]}>
                    {item?.address}
                  </p>
                ) : (
                  <></>
                )}
                {liveSchedules?.getLiveSchedule?.data?.find(
                  (i) => i?.event_part?.id === item?.id
                ) ? (
                  <DialogModal
                    fillScreen={true}
                    trigger={
                      <button className="bg-inawo-gold-300 rounded-[0.125rem] h-[2.75rem] px-[1.5rem] flex items-center gap-[0.5rem] border-solid !border border-black mb-[0.75rem] hover:opacity-90 ease-in-out duration-300 transition-opacity">
                        <Icon iconName="videoCamera" />
                        <span className="">Watch Live</span>
                      </button>
                    }
                  >
                    <LiveSchedules schedules={liveSchedules} />
                  </DialogModal>
                ) : (
                  <></>
                )}
                <div className="mb-3">
                  {currentAccessToken && (
                    <AddToCalendar
                      event={{
                        name: item.name,
                        details: null,
                        location: item?.address,
                        startsAt: item?.time,
                        endsAt: addOneDay(item?.time),
                      }}
                    >
                      <span>Save Date</span>
                    </AddToCalendar>
                  )}
                </div>
                {currentAccessToken &&
                  item?.geo_position?.geo_position_id !== "" && (
                    <div>
                      <DialogModal
                        trigger={
                          <button className="bg-transparent rounded-[5px] h-[3rem] px-[1.5rem] flex items-center gap-[0.5rem] border-solid !border border-black mb-[0.75rem] hover:opacity-90 ease-in-out duration-300 transition-opacity">
                            <span className="">View on Map</span>
                          </button>
                        }
                        open={openMap}
                        onOpenChange={setOpenMap}
                      >
                        <div className="max-w-[500px] h-auto bg-white rounded-[5px] p-[20px]">
                          <div className="mb-4 flex items-center justify-center gap-[5px]">
                            <Icon iconName="location" />
                            <h2 className="font-bold text-[18px] text-center">
                              {item?.address}
                            </h2>
                          </div>
                          {!isLoaded ? (
                            <h3>Loading Map.....</h3>
                          ) : (
                            <div>
                              <div className="w-[100%] h-[350px] mb-4">
                                <GoogleMap
                                  center={{
                                    lat: Number(item?.geo_position?.latitude),
                                    lng: Number(item?.geo_position?.longitude),
                                  }}
                                  mapContainerStyle={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  zoom={17}
                                >
                                  <MarkerF
                                    position={{
                                      lat: Number(item?.geo_position?.latitude),
                                      lng: Number(
                                        item?.geo_position?.longitude
                                      ),
                                    }}
                                    onLoad={onLoadMarker}
                                  />
                                </GoogleMap>
                              </div>
                              <div className="flex">
                                <button
                                  onClick={() =>
                                    handleViewDirections({
                                      latitude: Number(
                                        item?.geo_position?.latitude
                                      ),
                                      longitude: Number(
                                        item?.geo_position?.longitude
                                      ),
                                    })
                                  }
                                  className="bg-transparent rounded-[5px] mx-auto h-[3rem] px-[1.5rem] flex items-center gap-[0.5rem] border-solid !border border-black hover:opacity-90 ease-in-out duration-300 transition-opacity"
                                >
                                  <span className="">View directions</span>
                                </button>
                                <button
                                  onClick={() =>
                                    handleFindHotelsNearby({
                                      latitude: Number(
                                        item?.geo_position?.latitude
                                      ),
                                      longitude: Number(
                                        item?.geo_position?.longitude
                                      ),
                                    })
                                  }
                                  className="bg-transparent rounded-[5px] mx-auto h-[3rem] px-[1.5rem] flex items-center gap-[0.5rem] border-solid !border border-black hover:opacity-90 ease-in-out duration-300 transition-opacity"
                                >
                                  <span className="">Find hotels around</span>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </DialogModal>
                    </div>
                  )}{" "}
              </motion.div>
            );
          })}
        </motion.div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Parts;
